import { ContentBlocks } from "src/ui/ContentBlocks";
import { SeoHead } from "src/ui/SeoHead";

import * as additionalComponents from "./__components";

export default function HomePage({ prefix, title, canonical, data }) {
  return (
    <>
      <SeoHead title={title} canonical={canonical} />
      <ContentBlocks data={data} prefix={prefix} additionalComponents={additionalComponents} />
    </>
  );
}

export async function getStaticProps() {
  return {
    props: {
      prefix: "home",
      title: "Home",
      canonical: "/",
      data: [
        {
          component: "HeroHome",
          props: {
            tagline: "Award Winning Experts in Recruitment.",
            title: "Global Life Science <b>Recruitment.</b>",
            video: "/assets/home_hero.min.mp4",
            description:
              "<p>Award Winning Pharmaceutical & Life Science Recruitment Consultancy. Changing Lives One Job at a Time.</p>",
            buttons: [
              {
                label: "For Clients",
                url: "/clients",
                variant: "blue",
              },
              {
                label: "For Candidates",
                url: "/candidates",
                variant: "cyan",
              },
            ],
          },
        },
        {
          component: "HomeAboutContent",
          props: {
            tagline: "Welcome to Hyper Recruitment Solutions (HRS)",
            title:
              "Founded in 2012, Hyper Recruitment Solutions (HRS) is a multi-award-winning recruitment agency specializing in STEM roles within the life sciences sector, offering a global service with a personal touch.",
            content:
              "<p>Co-founded by Ricky Martin and Lord Alan Sugar, our team combines over 200 years of recruitment experience, with two-thirds of our professionals having deep STEM backgrounds.</p><p>Our commitment extends beyond recruitment; we share your passion for advancing life-saving medicines and transformative technologies. This dedication enables us to provide solutions that address the unique needs of life sciences organizations and drive meaningful innovation worldwide.</p><p>Discover how our award-winning approach can enhance your recruitment strategy or advance your career, and join us in making a significant impact on global health and technology.</p>",
            buttons: [{ label: "About us", url: "/about-us", variant: "blue" }],
          },
        },
        { component: "StatisticsHexagons", containerProps: { className: "position-relative z-2" } },
        {
          component: "AreasOfExpertise",
          containerProps: { className: "position-relative z-1" },
        },
        {
          component: "JobsCarousel",
          props: { title: "Latest Jobs", buttons: [] },
          containerProps: { className: "position-relative z-2" },
        },
        // {
        //   component: "ContentSideImages",
        //   props: {
        //     className: "py-5 pt-md-7 pb-md-8",
        //     // reverse: true,
        //     tagline: "Science Quiz ",
        //     title: "Find out what type of scientist you are",
        //     image_1: "/assets/about_csr_1.webp",
        //     // image_2: "/assets/about_csr_2.webp",
        //     description: "<p></p>",
        //     buttons: [
        //       {
        //         label: "Find out",
        //         url: "https://quiz.hyperec.com/",
        //         variant: "blue",
        //       },
        //     ],
        //   },
        // },
        {
          component: "JobSearchForm",
          containerProps: { className: "position-relative z-3" },
        },
        // {
        //   component: "ServicesCarousel",
        //   props: {
        //     title: "Our Services",
        //     description: "We deliver comprehensive recruitment and consulting solutions.",
        //     buttons: [{ label: "Find out more", url: "/clients", variant: "cyan" }],
        //   },
        // },
        {
          component: "ServicesNonSlider",
          props: {
            title: "Our Services",
            description: "We deliver comprehensive recruitment and consulting solutions.",
            buttons: [{ label: "Find out more", url: "/clients", variant: "cyan" }],
          },
        },
        { component: "ReviewsCarousels" },
        {
          component: "BlogPostsCarousel",
          props: { className: "pt-5 pb-6 pt-md-6" },
          containerProps: { animation: "zoom-out" },
        },
      ],
    },
  };
}
